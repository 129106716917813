<template>
	<div class="admin-menu">
		<p class="head" :class="{ active: isActive }" @click="isActive = !isActive">
			Панель управления
			<svg width="5" height="5" viewBox="0 0 5 5" fill="none" class="arrow" style="opacity: 0.7;"><path data-v-5caa031f="" d="M2.71083 4.57612L4.96764 0.664847C5.06 0.50422 4.94355 0.303436 4.75883 0.303436L0.241189 0.303436C0.056468 0.303436 -0.0599871 0.50422 0.0323739 0.664847L2.28918 4.57612C2.3856 4.73674 2.61847 4.73674 2.71083 4.57612Z" fill="black"></path></svg>
		</p>
		<div class="links">
			<router-link class="link" v-for="link in AdminMenu" :key="link.title" :to="{ name: link.route }" @click.native="isActive = false">{{ link.title }}</router-link>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			AdminMenu: [
				{title: 'Пользователи', route: 'AdminUsers'},
				{title: 'Филиалы', route: 'AdminBranches'},
				{title: 'Перезакрепить', route: 'Empty'},
				{title: 'Жалобы', route: 'Empty'},
				{title: 'Презентация', route: 'Empty'},
				{title: 'Прозвон', route: 'Empty'},
				{title: 'Статистика', route: 'Empty'},
				{title: 'Добавить АН.ЖК', route: 'Empty'},
				{title: 'Вопросы', route: 'Empty'},
				{title: 'Статьи', route: 'Empty'},
				{title: 'О нас', route: 'Empty'},
				{title: 'Импорт', route: 'Empty'},
				{title: 'Экспорт', route: 'Empty'},
				{title: 'Реклама город', route: 'Empty'},
				{title: 'Копирование', route: 'Empty'},
			],
			isActive: false
		}
	},
}
</script>

<style lang="sass" scoped>
.admin-menu
	background: #fff
	box-shadow: 4px 9px 16px rgba(113, 113, 113, 0.1)
	border-radius: 4px
	.head
		display: flex
		justify-content: space-between
		align-items: center
		font-size: 16px
		font-weight: 700
		text-transform: uppercase
		padding: 25px 29px 15.5px 29px
		margin: 0
		.arrow
			display: none
			width: 8px
			height: 8px
	.links
		display: flex
		flex-direction: column
		font-size: 16px
		padding: 0 29px 19.5px 29px
		.link
			padding: 5.5px 0
			transition: 0.3s
			&.router-link-active,
			&:hover
				color: #04B67D

@media only screen and (max-width : 1000px)
	.admin-menu
		.head
			padding-top: 15.5px
			cursor: pointer
			.arrow
				display: block
			&+ .links
				display: none
		.head.active 
			&+ .links
				display: flex
			.arrow
				transform: rotate(180deg)
</style>